import { useEffect, useMemo } from 'react';
import cloneDeep from 'lodash/fp/cloneDeep';
import WorkflowAside from '../../components/workflows/WorkflowAside';
import WorkflowHeader from '../../components/workflows/WorkflowHeader';
import WorkflowPlaceholder from '../../components/workflows/WorkflowPlaceholder';
import WorkflowPlot from '../../components/workflows/WorkflowPlot';
import { useWorkflowContext } from '../../components/workflows/WorkflowProvider';
import { WorkflowReducerActionTypes } from '../../components/workflows/WorkflowProvider/reducer';
import WorkflowsList from '../../components/workflows/WorkflowsList';
import { useWorkflowsQuery } from '../../queries/workflows';
import { createNewWorkflow, findNode } from '../../components/workflows/utils';
import WorkflowStepConfigurator from '../../components/workflows/WorkflowStepConfigurator';
import Loading from '../../components/Loading/Loading';
import { WorkflowAsideSteps } from '../../components/workflows/WorkflowAside/WorkflowAsideSteps';
import { WorkflowAsideTriggers } from '../../components/workflows/WorkflowAside/WorkflowAsideTriggers';
import { useParams } from 'react-router';
import { Box } from '@mui/material';

export function Workflows() {
    const {
        state: { workflow, selectedStepId },
        dispatch,
    } = useWorkflowContext();
    const { workflowId } = useParams();
    const { workflows, isPending } = useWorkflowsQuery();

    useEffect(() => {
        if (workflowId === 'new') {
            dispatch({
                type: WorkflowReducerActionTypes.SET_WORKFLOW,
                payload: createNewWorkflow(),
            });
        }
    }, [workflowId, dispatch]);

    useEffect(() => {
        if (workflowId === 'new') {
            return;
        }

        if (workflowId) {
            const selectedWorkflow = workflows.find(
                (workflow) => workflow.id === Number.parseInt(workflowId, 10),
            );

            if (selectedWorkflow) {
                dispatch({
                    type: WorkflowReducerActionTypes.SET_WORKFLOW,
                    payload: cloneDeep(selectedWorkflow),
                });
            }
        } else {
            dispatch({
                type: WorkflowReducerActionTypes.SET_WORKFLOW,
                payload: null,
            });
        }
    }, [workflowId, isPending, dispatch, workflows]);

    const selectedStep = useMemo(() => {
        if (workflow && selectedStepId && selectedStepId !== 'TRIGGER') {
            return findNode(selectedStepId, workflow.spec.steps);
        }
        return null;
    }, [selectedStepId, workflow]);

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: workflowId ? '1fr auto' : '1fr',
            }}
        >
            <Box
                component="section"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <WorkflowHeader />
                {!isPending && !workflowId && workflows.length === 0 && (
                    <WorkflowPlaceholder />
                )}
                {!isPending && !workflowId && workflows.length > 0 && (
                    <WorkflowsList />
                )}
                {workflow && <WorkflowPlot />}
                {isPending && <Loading />}
            </Box>
            {workflow && (
                <Box
                    component="aside"
                    sx={{
                        maxHeight: 1,
                        width: 320,
                        borderLeft: '1px solid',
                        borderLeftColor: 'custom.gray.divider',
                    }}
                >
                    {selectedStepId === 'TRIGGER' ? (
                        <WorkflowAside title="Trigger" showCloseButton>
                            <WorkflowAsideTriggers />
                        </WorkflowAside>
                    ) : selectedStep ? (
                        <WorkflowStepConfigurator step={selectedStep} />
                    ) : (
                        <WorkflowAside title="Workflow Items">
                            <WorkflowAsideSteps />
                        </WorkflowAside>
                    )}
                </Box>
            )}
        </Box>
    );
}
