import { useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import EmailStep from './EmailStep';
import CodeStep from './CodeStep';
import { Step } from './utils';
import { useTeams } from 'msteams-react-base-component';
import { signInViaTeams } from '../../api/auth';
import Loading from '../Loading/Loading';
import { getMe } from '../../api/user';
import { queryClient } from '../../queries/queryClient';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { CURRENT_USER_KEY } from '../../queries/user';
import { useLocation } from 'react-router-dom';
import { LoginHeader } from './LoginHeader';

const LoginForm = () => {
    const [step, setStep] = useState<Step>(Step.Email);
    const [email, setEmail] = useState('');
    const [loggingInWithTeams, setLoggingInWithTeams] =
        useState<boolean>(false);
    const [methodId, setMethodId] = useState('');
    const [{ inTeams }] = useTeams();
    const navigate = useNavigate();
    const location = useLocation();
    const isSignUp = location.pathname !== '/login';

    useEffect(() => {
        if (inTeams) {
            setLoggingInWithTeams(true);
            microsoftTeams.authentication
                .getAuthToken()
                .then(async (res) => {
                    await signInViaTeams(res);
                    const me = await getMe();
                    queryClient.setQueryData([CURRENT_USER_KEY], me);
                    navigate('/');
                    setLoggingInWithTeams(false);
                })
                .catch((e) => {
                    setLoggingInWithTeams(false);
                    throw new Error(e);
                });
        }
    }, [inTeams, navigate]);

    return loggingInWithTeams ? (
        <Loading />
    ) : (
        <Box component="section" textAlign="center">
            <LoginHeader step={step} isSignUp={isSignUp} />

            {step === Step.Email && (
                <EmailStep
                    email={email}
                    setEmail={setEmail}
                    setMethodId={setMethodId}
                    next={() => setStep(Step.Code)}
                    isSignUp={isSignUp}
                />
            )}

            {step === Step.Code && (
                <CodeStep
                    methodId={methodId}
                    email={email}
                    setMethodId={setMethodId}
                    next={() => navigate('/select-team')}
                    isSignUp={isSignUp}
                />
            )}
        </Box>
    );
};

export default LoginForm;
