import { Button, SxProps, Theme } from '@mui/material';
import { activeTab, tab } from './styles';
import { EmojiTab } from './types';

type Props = {
    emoji: EmojiTab;
    activeTabIndex: number;
    onClick: (index: number) => void;
};

export const Tab = ({ emoji, activeTabIndex, onClick }: Props) => {
    const onTabClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClick(emoji.groupKey);
    };
    return (
        <Button
            key={emoji.groupKey}
            sx={
                [
                    tab,
                    activeTabIndex === emoji.groupKey ? activeTab : {},
                ] as SxProps<Theme>
            }
            variant="text"
            size="small"
            onClick={onTabClick}
            title={emoji.groupName}
        >
            {emoji.groupEmoji}
        </Button>
    );
};
