import { useEffect, useState } from 'react';
import { checkIsFreeTeam, useMeQueryData } from '../../../../queries/user';
import { useGetMicrosoftTeamsPhones } from '../../../../queries/integrations';
import { useTeams } from 'msteams-react-base-component';

export enum Views {
    NonMSUser = 'nonMSUser',
    NoPermission = 'noPermission',
    WelcomeToWorkspace = 'welcomeToWorkspace',
    CallToVerify = 'callToVerify',
}

export const useTeamsNonWoOnboarding = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [view, setView] = useState<Views | null>(null);
    const me = useMeQueryData();
    const teamIsFree = checkIsFreeTeam();
    const { data: msPhones, isLoading: isPhonesLoading } =
        useGetMicrosoftTeamsPhones();
    const [{ inTeams }] = useTeams();

    const userIsAssignedAPhoneInMicrosoft = msPhones?.phones?.some(
        (phone) => phone?.user?.userPrincipalName === me?.email,
    );

    const userNotAssignedAndIsFreeTeam =
        !userIsAssignedAPhoneInMicrosoft && teamIsFree;

    useEffect(() => {
        if (!me?.microsoftId) {
            setView(Views.NonMSUser);
            setIsLoading(false);
        } else if (isPhonesLoading) {
            setIsLoading(true);
        } else if (userIsAssignedAPhoneInMicrosoft) {
            setView(Views.WelcomeToWorkspace);
            setIsLoading(false);
        } else if (userNotAssignedAndIsFreeTeam) {
            setView(Views.CallToVerify);
            setIsLoading(false);
        } else {
            setView(Views.NoPermission);
            setIsLoading(false);
        }
    }, [
        me,
        msPhones,
        isPhonesLoading,
        inTeams,
        teamIsFree,
        userNotAssignedAndIsFreeTeam,
        userIsAssignedAPhoneInMicrosoft,
    ]);

    return { isLoading, view };
};
