import { useState } from 'react';
import { ClerkPermission, Inbox } from '../../../api/types';
import { DeleteInbox } from './DeleteInbox';
import { Header } from '../Header';
import { HeaderActions } from './InboxForm/HeaderActions';
import { Divider, Stack } from '@mui/material';
import { FormRow } from './InboxForm/FormRow';
import { PhoneNumberRow } from './InboxForm/PhoneNumberRow';
import { DeleteRow } from './InboxForm/DeleteRow';
import { ArchiveInbox } from './ArchiveInbox';
import { WithPermission } from '../../WithPermission/WithPermission';

type Props = {
    inbox: Inbox;
    deleteInbox: boolean;
};

export function About({
    inbox,
    deleteInbox: defaultDeleteInbox = false,
}: Props) {
    const [deleteInbox, setDeleteInbox] = useState(defaultDeleteInbox);
    const [archiveInbox, setArchiveInbox] = useState(false);

    if (deleteInbox) {
        return (
            <DeleteInbox inbox={inbox} onClose={() => setDeleteInbox(false)} />
        );
    }

    if (archiveInbox) {
        return (
            <ArchiveInbox
                inbox={inbox}
                onClose={() => setArchiveInbox(false)}
            />
        );
    }

    return (
        <>
            <Header title="About Phone" description="General information">
                <HeaderActions
                    inboxId={inbox.id}
                    onArchiveAll={() => setArchiveInbox(true)}
                />
            </Header>
            <Divider />
            <Stack spacing={4} flex={1}>
                <FormRow inbox={inbox} />
                <PhoneNumberRow inbox={inbox} />
                <Divider />
                <WithPermission
                    requiredPermissions={[ClerkPermission.ModifyPrivateInbox]}
                >
                    <DeleteRow onDeleteInbox={() => setDeleteInbox(true)} />
                </WithPermission>
            </Stack>
        </>
    );
}
