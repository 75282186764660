import { useEffect } from 'react';
import { isInIframe } from '../utils/isInIframe';
import * as storage from '../utils/local-storage';

export const useSaveCrmExternalId = () => {
    useEffect(() => {
        if (isInIframe()) {
            const params = Object.fromEntries(
                new URLSearchParams(window.location.search),
            );
            storage.set(
                'crmExternalId',
                ((params.externalIdName && params[params.externalIdName]) ||
                    params.externalId) ??
                    null,
            );
        }
    }, []);
};
