import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import cc from 'classcat';
import { useDiscussion } from '../../queries/comments';
import { useEnabledFeature, useTeammates } from '../../queries/user';
import DiscussionMessageBox from '../DiscussionMessageBox';
import MessageComment from '../MessageComment';
import styles from './ConversationDiscussion.module.scss';
import {
    getOriginalMessageAuthor,
    getOriginalMessageAvatar,
    isScrollAtTheBottom,
    scrollToBottom,
    scrollToTop,
    transformMessages,
} from './utils';
import { Feature, SenderType } from '../../api/types';
import { CloseIcon } from '../../icons/common/CCloseIcon';
import { Divider, IconButton } from '@mui/material';
import { pluralize } from '../../utils/pluralize';
import { colors } from '../../theme/palette';

interface ConversationDiscussionProps {
    discussionId: number;
    onClose: () => void;
}

export const ConversationDiscussion = (props: ConversationDiscussionProps) => {
    const [scrollMode, setScrollMode] = useState<'auto' | 'manual'>('manual');
    const { discussion, isFetched } = useDiscussion(props.discussionId);
    const { data: teammates = [] } = useTeammates();

    const scrollRef = useRef<HTMLDivElement>(null);

    const events = transformMessages(discussion?.comments, teammates);

    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    useEffect(() => {
        const scrollHandler = () => {
            if (isScrollAtTheBottom(scrollRef)) {
                setScrollMode('auto');
            } else {
                setScrollMode('manual');
            }
        };

        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', scrollHandler);

            return () =>
                // eslint-disable-next-line react-hooks/exhaustive-deps
                scrollRef.current?.removeEventListener('scroll', scrollHandler);
        }
    }, []);

    useLayoutEffect(() => {
        if (isFetched) {
            scrollToTop(scrollRef);
        }
    }, [props.discussionId, isFetched]);

    useLayoutEffect(() => {
        if (isFetched && scrollMode === 'auto') {
            scrollToBottom(scrollRef);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetched, discussion?.comments]);

    return (
        <section className={styles['root']}>
            <header className={styles['root__header']}>
                <h6 className={styles['root__title']}>Discussion</h6>
                <IconButton onClick={props.onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </header>

            <main className={styles['root__content']} ref={scrollRef}>
                {discussion?.message && (
                    <MessageComment
                        className={cc([
                            styles['root__original-message'],
                            {
                                [styles['root__inbound']]:
                                    discussion.message.inbound,
                                [styles['root__campaign']]:
                                    discussion.message.senderType ===
                                    SenderType.CAMPAIGN,
                                [styles['root__workflow']]:
                                    discussion.message.senderType ===
                                    SenderType.WORKFLOW,
                            },
                        ])}
                        comment={{
                            id: discussion.message.id,
                            discussionId: discussion.id,
                            text: discussion.message.body,
                            userId: discussion.message.user?.id,
                            created: discussion.message.created,
                        }}
                        senderName={getOriginalMessageAuthor(
                            discussion.message,
                            isMultipleContactPhonesEnabled,
                        )}
                        senderAvatar={getOriginalMessageAvatar(
                            discussion.message,
                            isMultipleContactPhonesEnabled,
                        )}
                    />
                )}

                <div className={styles['root__comments']}>
                    {events.length > 0 && (
                        <Divider
                            sx={({ typography }) => ({
                                ...typography.body4,
                                my: 3,
                                color: colors.superGrey[500],
                            })}
                        >
                            {pluralize(events.length, 'reply', 'replies')}
                        </Divider>
                    )}

                    <ul className={styles['root__comments-list']}>
                        {events.map((event) => (
                            <li
                                key={event.comment.id}
                                className={styles['root__comments-list-item']}
                            >
                                <MessageComment
                                    comment={event.comment}
                                    senderName={event.author}
                                    senderAvatar={event.avatar}
                                    attachments={event.comment.attachments}
                                />
                            </li>
                        ))}
                    </ul>
                </div>

                <DiscussionMessageBox discussionId={props.discussionId} />
            </main>
        </section>
    );
};
