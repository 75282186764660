import { useTeams } from 'msteams-react-base-component';
import Loading from '../../components/Loading/Loading';
import useUnmutedSortedInboxes from '../../hooks/useUnmutedSortedInboxes';
import { useEnabledFeature, useMeQueryData } from '../../queries/user';
import { Redirect } from '../../utils/redirect';
import { ClerkPermission, Feature, InboxStatus } from '../../api/types';
import { usePermission } from '../WithPermission/WithPermission';
import { useMemo } from 'react';
import { useCrmExternalConversation } from '../../hooks/use-crm-external-conversation';

export const InboxLoader = () => {
    const currentUser = useMeQueryData();
    const { unmutedSortedInboxes, isPending } = useUnmutedSortedInboxes();
    const { waitForExternalConversation, externalConversation } =
        useCrmExternalConversation(!!currentUser);

    const [{ inTeams }] = useTeams();

    const canSetupInboxes = usePermission(ClerkPermission.AddInbox);
    const canViewMessages = usePermission(ClerkPermission.ViewInboxMessages);
    const hasFeature = useEnabledFeature(Feature.AddInbox);

    const activeInboxes = useMemo(() => {
        return unmutedSortedInboxes.filter(
            (inbox) => inbox.status === InboxStatus.ACTIVE,
        );
    }, [unmutedSortedInboxes]);

    if (isPending || waitForExternalConversation) {
        return <Loading />;
    }

    if (currentUser) {
        if (externalConversation) {
            return (
                <Redirect
                    to={`/inbox/${externalConversation.inboxId}/conversations/${externalConversation.id}`}
                />
            );
        }

        if (activeInboxes.length === 0 && canSetupInboxes && hasFeature) {
            if (inTeams) {
                return <Redirect to="/setup-inbox/ms-teams/auth" />;
            }
            return <Redirect to="/setup-inbox" />;
        }

        if (activeInboxes.length > 0 && canViewMessages) {
            return <Redirect to={`/inbox/${activeInboxes[0].id}`} />;
        }

        return <Redirect to="/contacts/book" />;
    }

    return <Redirect to="/login" />;
};
