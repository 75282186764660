import {
    Box,
    Button,
    Dialog,
    DialogActions,
    Divider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DialogAttentionIcon } from '../../../elements/Dialog/DialogAttentionIcon';
import { DialogTitleTwoLine } from '../../../elements/Dialog/DialogTitleTwoLine';
import { ArrowBackIcon } from '../../../icons/common/CArrowBack';
import { ButtonDotLoader } from '../../../components/Forms/ButtonDotLoader';
import { useMemo } from 'react';
import {
    PlanItem,
    PlanPeriod,
    getPlansByType,
} from '../../../containers/PaymentFlow/plans';
import { colors } from '../../../theme/palette';
import { useUpdateTeamSubscription } from '../../../queries/billing';
import { StripeMainSubscription } from '../../../api/billing';
import { FlagIcon } from '../../../icons/common/CFlag';
import { getSquare } from '../../../theme/style.helpers';

const billingCycleName = {
    yearly: 'yearly',
    monthly: 'monthly',
};

const periodName = {
    yearly: 'year',
    monthly: 'month',
};

type Fields = { period: PlanPeriod };
type Props = {
    open: boolean;
    onClose: () => void;
    onSave: (c: Fields) => void;
    licenseCount: number;
    activePlan: PlanItem;
    subscription: StripeMainSubscription;
};

export const ChangePeriodModal = ({
    open,
    onClose,
    onSave,
    licenseCount,
    activePlan,
    subscription,
}: Props) => {
    const { mutateAsync: update, isPending } = useUpdateTeamSubscription();

    const plans = useMemo(
        () => getPlansByType(activePlan.type),
        [activePlan.type],
    );

    const notActivePlans = useMemo(() => {
        return Object.values(plans).filter(
            (p) => p.priceId !== activePlan.priceId,
        );
    }, [plans, activePlan.priceId]);

    const form = useForm<Fields>({
        defaultValues: { period: notActivePlans[0].period },
    });
    const selectedPeriodName = form.watch('period');

    const selectedPlan = useMemo(
        () => notActivePlans.find((p) => p.period === selectedPeriodName),
        [notActivePlans, selectedPeriodName],
    );

    const onSubmit = async (fields: Fields) => {
        if (!selectedPlan) {
            return;
        }
        await update({
            subscriptionItemId: subscription.id,
            priceId: selectedPlan.priceId,
            seats: licenseCount,
        });
        onSave(fields);
    };
    const total = useMemo(() => {
        if (!selectedPlan) {
            return 0;
        }
        if (activePlan.period === 'monthly') {
            return selectedPlan.price * licenseCount;
        }
        return (
            Math.round(selectedPlan.price * licenseCount * 12 * 100) / 100
        ).toFixed(2);
    }, [selectedPlan, licenseCount, activePlan.period]);
    return (
        <Dialog
            open={open}
            onClose={() => {
                form.reset();
                onClose();
            }}
        >
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <DialogAttentionIcon color="primary" />
                <DialogTitleTwoLine
                    title="Change billing period"
                    subtitle="Choose wether you are billed monthly or yearly"
                />
                <Box sx={{ px: 6, pb: 6, width: 372, maxWidth: 1 }}>
                    <Box
                        sx={{
                            background: 'rgba(236, 239, 254, 0.24)',
                            border: '1px solid',
                            borderColor: colors.lightGrey[400],
                            borderRadius: 1.5,
                            px: 3,
                            py: 2.5,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <FlagIcon sx={getSquare(20)} />
                            <Typography variant="body3">
                                Current period
                            </Typography>
                        </Box>

                        <Typography variant="body3" color="info.main">
                            {' '}
                            <Typography
                                variant="body3"
                                color="info.main"
                                sx={{
                                    textTransform: 'capitalize',
                                    fontWeight: 500,
                                }}
                            >
                                {activePlan.period}
                            </Typography>
                            {`
                         ($${activePlan.price.toFixed(2)} / license)`}
                        </Typography>
                    </Box>
                    <Typography
                        variant="body4"
                        color="text.secondary"
                        sx={{ pt: 4, pb: 1, display: 'block' }}
                    >
                        Available for change:
                    </Typography>
                    <FormControl sx={{ width: 1, pb: 3 }}>
                        <Controller
                            control={form.control}
                            name="period"
                            render={({ field }) => (
                                <RadioGroup
                                    {...field}
                                    aria-labelledby="list-access-radio-label"
                                    row
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: 1,
                                        gap: 2,
                                    }}
                                >
                                    {notActivePlans.map((plan) => {
                                        return (
                                            <FormControlLabel
                                                key={plan.period}
                                                value={plan.period}
                                                control={
                                                    <Radio
                                                        size="small"
                                                        color="info"
                                                    />
                                                }
                                                sx={{
                                                    width: '100%',
                                                    '& > .MuiFormControlLabel-label':
                                                        {
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            width: '100%',
                                                        },
                                                }}
                                                label={
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body3"
                                                            sx={{
                                                                textTransform:
                                                                    'capitalize',
                                                            }}
                                                        >
                                                            {plan.period}
                                                        </Typography>
                                                        <Typography variant="body3">
                                                            $
                                                            {plan.price.toFixed(
                                                                2,
                                                            )}{' '}
                                                            / license
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            pt: 4,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography color="text.secondary" variant="body4">
                                Plan price{' '}
                                {`(${billingCycleName[activePlan.period]})`}
                            </Typography>
                            <Typography color="text.secondary" variant="body4">
                                ${selectedPlan?.price.toFixed(2)}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography color="text.secondary" variant="body4">
                                Current number of licenses
                            </Typography>
                            <Typography color="text.secondary" variant="body4">
                                {licenseCount}
                            </Typography>
                        </Box>
                        {activePlan.period === 'monthly' && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body4"
                                >
                                    Annual subscription
                                </Typography>
                                <Typography
                                    color={colors.green[600]}
                                    variant="body4"
                                >
                                    -20%
                                </Typography>
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant="body3">Total price</Typography>
                            <Typography variant="h4">
                                ${total.toLocaleString('en-US')} /{' '}
                                {periodName[activePlan.period]}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <DialogActions>
                    <Button
                        sx={{ minWidth: 96 }}
                        onClick={() => {
                            form.reset();
                            onClose();
                        }}
                        size="large"
                        startIcon={<ArrowBackIcon />}
                        variant="outlined"
                    >
                        Back
                    </Button>
                    <Button
                        size="large"
                        fullWidth
                        type="submit"
                        disabled={isPending || !selectedPlan}
                    >
                        {isPending ? <ButtonDotLoader /> : 'Change the plan'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
