import { Discussion, Message, MessageComment } from '../api/types';
import { InfiniteData } from '@tanstack/react-query';
import { queryClient } from '../queries/queryClient';
import { DISCUSSION_KEY } from '../queries/comments';
import map from 'lodash/map';
import { CONVERSATION_MESSAGES_KEY } from '../queries/messages';

export const MESSAGE_COMMENT_CREATED_EVENT = 'message_comment:created';
type Payload = {
    comment: MessageComment;
    discussion: Discussion;
};
export type MessageCommentCreatedHandler = (payload: Payload) => void;

const updateConversationMessages =
    (discussion: Discussion) =>
    (prev: InfiniteData<Message[], number> | undefined) => {
        if (!prev) {
            return prev;
        }
        return {
            ...prev,
            pages: prev.pages.map((messages) =>
                messages.map((message) =>
                    message.id === discussion.message.id
                        ? {
                              ...message,
                              discussion: message.discussion
                                  ? {
                                        ...message.discussion,
                                        ...discussion,
                                    }
                                  : discussion,
                          }
                        : message,
                ),
            ),
        };
    };

export const handleMessageCommentCreatedEvent: MessageCommentCreatedHandler = ({
    comment,
    discussion,
}: Payload) => {
    queryClient.setQueryData<Discussion>(
        [DISCUSSION_KEY, discussion.id],
        (prev) => {
            if (!prev) {
                return discussion;
            }
            const comments = prev.comments || [];
            return {
                ...prev,
                ...discussion,
                comments: map(comments, 'id').includes(comment.id)
                    ? comments
                          .map((item) =>
                              item.id === comment.id
                                  ? { ...item, ...comment }
                                  : item,
                          )
                          .filter(({ id }) => id > 0)
                    : comments.concat(comment).filter(({ id }) => id > 0),
            };
        },
    );

    queryClient.setQueryData<InfiniteData<Message[], number>>(
        [CONVERSATION_MESSAGES_KEY, discussion?.message?.conversationId],
        updateConversationMessages(discussion),
    );
};
