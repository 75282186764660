import { ClerkIconProps } from '../../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const ForkKnifeIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 2.25C5.41421 2.25 5.75 2.58579 5.75 3V8C5.75 8.97966 6.37611 9.81309 7.25 10.122V3C7.25 2.58579 7.58579 2.25 8 2.25C8.41421 2.25 8.75 2.58579 8.75 3V10.122C9.62389 9.81309 10.25 8.97966 10.25 8V3C10.25 2.58579 10.5858 2.25 11 2.25C11.4142 2.25 11.75 2.58579 11.75 3V8C11.75 9.81422 10.4617 11.3275 8.75 11.675V21C8.75 21.4142 8.41421 21.75 8 21.75C7.58579 21.75 7.25 21.4142 7.25 21V11.675C5.53832 11.3275 4.25 9.81422 4.25 8V3C4.25 2.58579 4.58579 2.25 5 2.25ZM14.25 3C14.25 2.58579 14.5858 2.25 15 2.25C17.6234 2.25 19.75 4.37665 19.75 7V9C19.75 11.3682 18.017 13.3315 15.75 13.6911V21C15.75 21.4142 15.4142 21.75 15 21.75C14.5858 21.75 14.25 21.4142 14.25 21V3ZM15.75 12.163C17.1834 11.8245 18.25 10.5368 18.25 9V7C18.25 5.46321 17.1834 4.17555 15.75 3.83697V12.163Z"
                fill="#667085"
            />
        </svg>
    </SvgIcon>
);
