import { Box, Typography } from '@mui/material';
import { PlanCard, PlanCardType } from './PlanCard';
import { useMemo, useState } from 'react';
import { planList } from '../../../containers/PaymentFlow/plans';
import { DotDivider } from '../../../components/common/DotDivider';
import { pluralize } from '../../../utils/pluralize';
import { ChangePlanModal } from './ChangePlanModal';
import { StripeMainSubscription } from '../../../api/billing';
import { useLicenseCount } from '../../../hooks/use-license-count';

export const Plan = ({
    subscription,
    disabled,
}: {
    subscription: StripeMainSubscription;
    disabled: boolean;
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const licenseCount = useLicenseCount();

    const plan = useMemo(
        () => planList.find((plan) => plan.priceId === subscription?.planId),
        [subscription?.planId],
    );
    if (!plan) {
        return null;
    }
    return (
        <>
            <PlanCard
                onChange={() => setIsModalOpen(true)}
                title={`${plan?.title ?? 'Free'} plan`}
                subtitle={
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Typography variant="body4">
                            ${plan?.price} / license
                        </Typography>
                        <DotDivider />
                        <Typography variant="body4">
                            {pluralize(licenseCount, 'license', 'licenses')}
                        </Typography>
                    </Box>
                }
                disabled={disabled}
                cardType={PlanCardType.Plan}
            />
            {isModalOpen && (
                <ChangePlanModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSave={() => setIsModalOpen(false)}
                    licenseCount={licenseCount}
                    activePlan={plan}
                    subscription={subscription}
                />
            )}
        </>
    );
};
