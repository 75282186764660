import { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Discussion, Message, MessageComment } from '../api/types';
import { PagedData } from '../types/PagedData';
import { UUID } from '../types/uuid';
import { CONVERSATION_MESSAGES_KEY } from './messages';
import { queryClient } from './queryClient';
import { getCurrentUser } from './user';
import { findPageIndexInPagedData } from './utils';
import clone from 'lodash/clone';
import setWith from 'lodash/setWith';
import { client } from '../api/http';
import map from 'lodash/map';

export const DISCUSSION_KEY = 'discussion';
export const useDiscussion = (discussionId: number) => {
    const {
        data: discussion,
        isFetching,
        isFetched,
        isError,
    } = useQuery({
        queryKey: [DISCUSSION_KEY, discussionId],
        queryFn: () =>
            client
                .get(`/message-comments/discussion/${discussionId}`)
                .then(({ data }) => data),
        select: (discussion) =>
            discussion.lastReplyDate
                ? {
                      ...discussion,
                      lastReplyDate: new Date(discussion.lastReplyDate),
                  }
                : discussion,
    });

    return { discussion, isFetching, isFetched, isError };
};

export interface CreateDiscussionDto {
    messageId: number;
}

export const useCreateDiscussion = (conversationId: UUID) => {
    return useMutation<Discussion, AxiosError, CreateDiscussionDto>({
        mutationKey: ['discussion_create'],
        mutationFn: (dto: CreateDiscussionDto): Promise<Discussion> =>
            client
                .post('/message-comments/discussion', dto)
                .then(({ data }) => data),
        onSuccess() {
            queryClient.refetchQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
            });
        },
    });
};

let tempId = -1;

export const useMessageCommentCreate = () =>
    useMutation({
        mutationFn: (dto: {
            discussionId: number;
            text: string;
            mentionedUserIds?: UUID[];
            fileIds?: string[];
        }): Promise<MessageComment> =>
            client.post('/message-comments', dto).then(({ data }) => data),
        onMutate: (dto) => {
            tempId--;
            const id = tempId;
            queryClient.setQueryData<Discussion>(
                [DISCUSSION_KEY, dto.discussionId],
                (prev) =>
                    prev
                        ? {
                              ...prev,
                              comments: prev.comments.concat({
                                  id,
                                  discussionId: dto.discussionId,
                                  created: new Date().toString(),
                                  userId: getCurrentUser()!.id,
                                  text: dto.text,
                                  mentionedUserIds: dto.mentionedUserIds,
                              }),
                          }
                        : prev,
            );

            return { tempId };
        },
        onError: (_err, dto, context) => {
            queryClient.setQueryData<Discussion>(
                [DISCUSSION_KEY, dto.discussionId],
                (prev) =>
                    prev
                        ? {
                              ...prev,
                              comments: prev.comments.filter(
                                  ({ id }) => id !== context?.tempId,
                              ),
                          }
                        : prev,
            );
        },
        onSuccess(created, dto, { tempId }) {
            queryClient.setQueryData<Discussion>(
                [DISCUSSION_KEY, dto.discussionId],
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    const comments = prev.comments.map((item) =>
                        item.id === created.id || item.id === tempId
                            ? { ...item, ...created }
                            : item,
                    );
                    return {
                        ...prev,
                        replies: prev.replies + 1,
                        comments: map(comments, 'id').includes(created.id)
                            ? comments
                            : comments.concat(created),
                    };
                },
            );
            const discussion = queryClient.getQueryData<Discussion>([
                DISCUSSION_KEY,
                dto.discussionId,
            ]);
            if (discussion) {
                updateReplies(discussion, discussion);
            }
        },
    });

export function updateReplies(prev: Discussion, next: Discussion) {
    queryClient.setQueryData<PagedData<Message>>(
        [CONVERSATION_MESSAGES_KEY, prev.message.conversationId],
        (pagedMessages = { pages: [], pageParams: [] }) => {
            const [pageIndex, itemIndex] = findPageIndexInPagedData(
                pagedMessages,
                (message) => message.id === prev.messageId,
            );

            return setWith(
                clone(pagedMessages),
                ['pages', pageIndex, itemIndex, 'discussion', 'replies'],
                next.replies,
                clone,
            );
        },
    );
}
