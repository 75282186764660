import { isInIframe } from '../utils/isInIframe';
import * as storage from '../utils/local-storage';
import { useConversationForExternalContact } from '../queries/conversations';

const useCrmExternalId = () => {
    if (!isInIframe()) {
        return null;
    }

    return storage.get('crmExternalId');
};

export const useCrmExternalConversation = (enabled = false) => {
    const crmExternalId = useCrmExternalId();
    const { isLoading, data } = useConversationForExternalContact(
        enabled ? crmExternalId : null,
    );

    return {
        waitForExternalConversation: isLoading,
        externalConversation: data,
    };
};
