import { useLocation, useNavigate } from 'react-router';
import { useEnabledFeature, useMeQuery } from '../queries/user';
import { usePermission } from '../containers/WithPermission/WithPermission';
import { useEffect, useMemo } from 'react';
import { ClerkPermission, Feature, InboxStatus, RoleTypes } from '../api/types';
import * as storage from '../utils/local-storage';
import { useInboxes } from '../queries/inboxes';

export const useNavigateToBlockedScreenOnboarding = () => {
    const { pathname } = useLocation();
    const enabled = useEnabledFeature(Feature.TeamsOnboarding);

    const navigate = useNavigate();
    const { data: me, isError } = useMeQuery();

    const { data: inboxes = [], isPending: isInboxesLoading } = useInboxes();

    const canSetupInboxes = usePermission(ClerkPermission.AddInbox);

    const activeInboxes = useMemo(() => {
        return inboxes.filter((inbox) => inbox.status === InboxStatus.ACTIVE);
    }, [inboxes]);

    useEffect(() => {
        if (
            !isInboxesLoading &&
            me &&
            activeInboxes.length === 0 &&
            !canSetupInboxes &&
            me?.activeTeam?.resellerId ===
                '367e3444-08c4-4a1f-a49d-87c07f32256b' &&
            me?.activeRole?.type !== RoleTypes.WorkspaceOwner &&
            !isError &&
            storage.get('auth') &&
            enabled
        ) {
            navigate('/invited/ms-teams');
        }
    }, [
        isInboxesLoading,
        activeInboxes.length,
        canSetupInboxes,
        me,
        navigate,
        pathname,
        isError,
        enabled,
    ]);
};
