import { useNavigate } from 'react-router';
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult,
} from '@hello-pangea/dnd';
import {
    ClerkPermission,
    Feature,
    Inbox,
    UserTeamSettingType,
} from '../../../api/types';
import CollapsibleSection from '../../../components/CollapsibleSection';
import useUnmutedSortedInboxes from '../../../hooks/useUnmutedSortedInboxes';
import { useEnabledFeature, useUpdateMeSettings } from '../../../queries/user';
import { WithPermission } from '../../WithPermission/WithPermission';
import { useTrack } from '../../../contexts/analytics';
import { useCallback } from 'react';
import { Box, Button } from '@mui/material';
import { list } from './styles';
import { InboxDraggableItem } from '../../../elements/InboxItem/InboxDraggableItem';
import { PlusIcon } from '../../../icons/common/CPlusIcon';
import { focusVisible } from '../../../theme/focusVisible';
import { WithFeature } from '../../Feature/WithFeature';

interface SideBarInboxesProps {
    setMenuOpen: (value: boolean) => void;
}

export const SideBarInboxes = ({ setMenuOpen }: SideBarInboxesProps) => {
    const { unmutedSortedInboxes } = useUnmutedSortedInboxes();
    const track = useTrack();
    const { mutate: updateSettings } = useUpdateMeSettings();
    const navigate = useNavigate();

    const handleOnDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            const newInboxesOrder = Array.from(unmutedSortedInboxes);
            const [reorderedItem] = newInboxesOrder.splice(
                result.source.index,
                1,
            );
            newInboxesOrder.splice(result.destination.index, 0, reorderedItem);

            updateSettings({
                enabled: true,
                type: UserTeamSettingType.InboxesOrder,
                data: {
                    ids: newInboxesOrder.map((inbox) => inbox.id),
                },
            });
        },
        [unmutedSortedInboxes, updateSettings],
    );

    const handleInboxItemClick = useCallback(() => {
        setMenuOpen(false);
    }, [setMenuOpen]);
    const canCreateNewNumber = useEnabledFeature(Feature.CreateNewNumber);
    const canImportNumber = useEnabledFeature(Feature.ImportNumber);
    const canAddInbox = useEnabledFeature(Feature.AddInbox);

    const handleAddButtonClick = useCallback(() => {
        track('clicked_create_new_inbox');

        return (!canCreateNewNumber && !canImportNumber) || !canAddInbox
            ? navigate('/payment/plan')
            : navigate('/setup-inbox/existing-number');
    }, [track, canCreateNewNumber, canImportNumber, canAddInbox, navigate]);

    return (
        <CollapsibleSection title="Phones" initiallyExpanded>
            <WithFeature feature={Feature.AddInbox}>
                <WithPermission
                    requiredPermissions={[ClerkPermission.ModifyTeam]}
                >
                    <Box px={4} py={2} mt={2}>
                        <Button
                            startIcon={<PlusIcon />}
                            color="info"
                            onClick={handleAddButtonClick}
                            fullWidth
                            data-navigation-element
                            sx={{
                                '&:focus-visible': {
                                    ...focusVisible,
                                    outlineOffset: '2px',
                                },
                            }}
                            tabIndex={0}
                        >
                            Add Number
                        </Button>
                    </Box>
                </WithPermission>
            </WithFeature>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="inboxes-list">
                    {(providedDroppable: DroppableProvided) => (
                        <Box
                            component="ul"
                            sx={list}
                            ref={providedDroppable.innerRef}
                            {...providedDroppable.droppableProps}
                            tabIndex={-1}
                        >
                            {unmutedSortedInboxes.map((inbox: Inbox, i) => (
                                <InboxDraggableItem
                                    key={inbox.id}
                                    index={i}
                                    inbox={inbox}
                                    onClick={handleInboxItemClick}
                                />
                            ))}
                            {providedDroppable.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>
        </CollapsibleSection>
    );
};
