import React, { createContext, useContext, useEffect, useState } from 'react';
import { Feature, Inbox, InboxProvider } from '../../api/types';
import { AllowedCountries, CarrierCredentials } from './types';
import { useEnabledFeature } from '../../queries/user';
import { useNavigate } from 'react-router';

interface SetupInboxContextProps {
    newInboxes: Partial<Inbox>[];
    setNewInboxes: React.Dispatch<React.SetStateAction<Partial<Inbox>[]>>;
    inboxCountry: AllowedCountries | undefined;
    setInboxCountry: React.Dispatch<
        React.SetStateAction<AllowedCountries | undefined>
    >;
    carrier: InboxProvider | undefined;
    setCarrier: React.Dispatch<React.SetStateAction<InboxProvider | undefined>>;
    carrierCredentials: CarrierCredentials | undefined;
    setCarrierCredentials: React.Dispatch<
        React.SetStateAction<CarrierCredentials | undefined>
    >;
}

const SetupInboxContext = createContext<SetupInboxContextProps | undefined>(
    undefined,
);

export const SetupInboxProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [newInboxes, setNewInboxes] = useState<Partial<Inbox>[]>([]);
    const [inboxCountry, setInboxCountry] = useState<AllowedCountries>();
    const [carrier, setCarrier] = useState<InboxProvider>();
    const [carrierCredentials, setCarrierCredentials] =
        useState<CarrierCredentials>();

    const hasFeature = useEnabledFeature(Feature.AddInbox);
    const navigate = useNavigate();

    useEffect(() => {
        if (!hasFeature) {
            navigate('/');
        }
    }, [hasFeature, navigate]);

    return (
        <SetupInboxContext.Provider
            value={{
                newInboxes,
                setNewInboxes,
                inboxCountry,
                setInboxCountry,
                carrier,
                setCarrier,
                carrierCredentials,
                setCarrierCredentials,
            }}
        >
            {children}
        </SetupInboxContext.Provider>
    );
};

export const useSetupInboxContext = () => {
    const context = useContext(SetupInboxContext);
    if (!context) {
        throw new Error('useInboxContext must be used within an InboxProvider');
    }
    return context;
};
