import { useCallback, useEffect, useState } from 'react';
import { handleMainSidebarKeyboardEvent } from '../containers/SideBar/helpers/handle-keyboard-event';
import { handleChatKeyboardEvent } from '../pages/NewInbox/helpers/handle-keyboard-event';
import { handleInboxKeyboardEvent } from '../components/Inbox/helpers/handle-keyboard-event';
import { handleModalKeyboardEvent } from '../containers/modal/handle-keyboard-event';

export enum NavigationArea {
    MainSidebar = 'main-sidebar',
    Inbox = 'inbox',
    Chat = 'chat',
    Modal = 'modal',
}

const isNavigationArea = (
    value: string | NavigationArea,
): value is NavigationArea => {
    return Object.values(NavigationArea).includes(value as NavigationArea);
};

const areaHandlersMap: Record<
    NavigationArea,
    (e: KeyboardEvent, section?: NavigationArea) => void
> = {
    [NavigationArea.MainSidebar]: handleMainSidebarKeyboardEvent,
    [NavigationArea.Inbox]: handleInboxKeyboardEvent,
    [NavigationArea.Chat]: handleChatKeyboardEvent,
    [NavigationArea.Modal]: handleModalKeyboardEvent,
};

export const useKeyboardNavigation = () => {
    const [section, setSection] = useState<NavigationArea | undefined>(
        NavigationArea.MainSidebar,
    );

    /* Handle keyboard events based on navigation area */
    const keyHandler = useCallback(
        (e: KeyboardEvent): void => {
            if (section && areaHandlersMap[section]) {
                areaHandlersMap[section](e);
                handleFocusChange();
            }
        },
        [section],
    );

    const handleFocusChange = () => {
        const activeElement = document.activeElement as HTMLElement | null;

        /* Traverse upwards from the active element to find the parent with the `data-navigation-area` attribute */
        const parentWithNavigationArea = activeElement?.closest<HTMLElement>(
            '[data-navigation-area]',
        );
        const navigationArea = parentWithNavigationArea?.dataset.navigationArea;
        if (navigationArea && isNavigationArea(navigationArea)) {
            setSection(navigationArea);
        } else {
            setSection(undefined);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', keyHandler);

        return () => {
            document.removeEventListener('keydown', keyHandler);
        };
    }, [keyHandler]);
};
