import {
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';
import { UUID } from '../../types/uuid';
import { MenuProps } from '../../hooks/use-menu';
import { SettingsIcon } from './CSettingsIcon';
import { MarkAsReadIcon } from './CMarkAsReadIcon';
import { CopyIcon } from '../../icons/shared/CopyIcon';
import { MuteIcon } from './CMuteIcon';
import { UnmuteIcon } from './CUnmuteIcon';
import {
    useArchiveAllForInbox,
    useInbox,
    useMarkAllAsReadForInbox,
} from '../../queries/inboxes';
import { ArchiveIcon } from './CArchiveIcon';
import { useTrack } from '../../contexts/analytics';
import { useIsMuted, useToggleInboxMute } from '../../queries/user';
import { ListItemIconLoader } from './ListItemIconLoader';
import { useState } from 'react';
import { DeleteIcon } from '../../icons/common/CDeleteIcon';
import { useSidebarContext } from './InboxSettingsContext';
import { TabName } from '../../containers/InboxSettings/InboxSettings';
import { MembersIcon } from '../../icons/common/CMembersIcon';
import { WithPermission } from '../../containers/WithPermission/WithPermission';
import { ClerkPermission } from '../../api/types';

type Props = {
    inboxId: UUID;
    menu: MenuProps;
};

const DEFAULT_COPY_TEXT = 'Copy number';
const COPIED_TEXT = 'Copied';

export const InboxContextMenu = ({
    menu: { anchorEl, open, handleClose },
    inboxId,
}: Props) => {
    const inbox = useInbox(inboxId);
    const track = useTrack();
    const isMuted = useIsMuted(inboxId);
    const toggleMute = useToggleInboxMute();
    const archiveAll = useArchiveAllForInbox();
    const markAsRead = useMarkAllAsReadForInbox();
    const [copyText, setCopyText] = useState(DEFAULT_COPY_TEXT);
    const { openInboxSettings } = useSidebarContext();

    if (!open) {
        return null;
    }

    return (
        <Menu
            open
            onClose={handleClose}
            id={`inbox-context-menu-${inboxId}`}
            anchorEl={anchorEl}
            transformOrigin={{
                horizontal: 'left',
                vertical: -8,
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{ '.MuiMenu-paper': { p: 1 } }}
        >
            <MenuItem
                onClick={(e) => {
                    e.preventDefault();
                    openInboxSettings({ inboxId });
                    track('inbox_settings_opened', {
                        inboxId,
                        origin: 'inbox_context_menu',
                    });
                    handleClose();
                }}
                tabIndex={0}
            >
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText>Settings</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={(e) => {
                    e.preventDefault();
                    openInboxSettings({ inboxId, tabName: TabName.Members });
                    track('inbox_members_opened', {
                        inboxId,
                        origin: 'inbox_context_menu',
                    });
                    handleClose();
                }}
                tabIndex={0}
            >
                <ListItemIcon>
                    <MembersIcon />
                </ListItemIcon>
                <ListItemText>Members</ListItemText>
            </MenuItem>
            <MenuItem
                disabled={markAsRead.isPending}
                onClick={(e) => {
                    e.preventDefault();
                    markAsRead
                        .mutateAsync({ inboxId })
                        .then(() => handleClose());
                }}
            >
                <ListItemIconLoader loading={markAsRead.isPending}>
                    <MarkAsReadIcon />
                </ListItemIconLoader>

                <ListItemText>Mark All Read</ListItemText>
            </MenuItem>
            <MenuItem
                disabled={archiveAll.isPending}
                onClick={(e) => {
                    e.preventDefault();
                    archiveAll
                        .mutateAsync({ inboxId })
                        .then(() => handleClose());
                }}
            >
                <ListItemIconLoader loading={archiveAll.isPending}>
                    <ArchiveIcon />
                </ListItemIconLoader>
                <ListItemText>Archive All</ListItemText>
            </MenuItem>
            <MenuItem
                disabled={toggleMute.isPending}
                onClick={(e) => {
                    e.preventDefault();
                    toggleMute.mutateAsync(inboxId).then(() =>
                        track(isMuted ? 'inbox_unmuted' : 'inbox_muted', {
                            inboxId,
                            origin: 'inbox_context_menu',
                        }),
                    );
                    handleClose();
                }}
            >
                <ListItemIconLoader loading={toggleMute.isPending}>
                    {isMuted ? <UnmuteIcon /> : <MuteIcon />}
                </ListItemIconLoader>

                <ListItemText>{isMuted ? 'Unmute' : 'Mute'}</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={(e) => {
                    e.preventDefault();
                    setCopyText(COPIED_TEXT);
                    navigator.clipboard.writeText(inbox.data?.phone || '');

                    track('inbox_phone_copied', {
                        inboxId,
                        origin: 'inbox_context_menu',
                    });

                    setTimeout(() => {
                        setCopyText(DEFAULT_COPY_TEXT);
                    }, 500);
                }}
            >
                <ListItemIcon>
                    <CopyIcon />
                </ListItemIcon>
                <ListItemText>{copyText}</ListItemText>
            </MenuItem>

            <WithPermission
                requiredPermissions={[ClerkPermission.ModifyPrivateInbox]}
            >
                <Divider />
                <MenuItem
                    onClick={(e) => {
                        e.preventDefault();
                        openInboxSettings({ inboxId, isDelete: true });
                        handleClose();
                    }}
                    sx={{
                        color: 'error.main',
                        '& .MuiListItemIcon-root': {
                            color: 'error.main',
                        },
                    }}
                >
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText>Delete phone</ListItemText>
                </MenuItem>
            </WithPermission>
        </Menu>
    );
};
