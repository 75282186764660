import { ClerkIconProps } from '../../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const WhatsAppIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                d="M0.416044 23.5848L2.05296 17.6356C1.04307 15.8949 0.51251 13.9192 0.51251 11.8964C0.515525 5.56607 5.69054 0.416138 12.0483 0.416138C15.1342 0.417138 18.0302 1.61359 20.2087 3.7834C22.3872 5.95321 23.586 8.8383 23.584 11.9054C23.581 18.2358 18.406 23.3858 12.0483 23.3858H12.0432C10.1129 23.3858 8.21574 22.9026 6.5306 21.9882L0.415039 23.5848H0.416044Z"
                fill="url(#paint0_linear_1018_11610)"
            />
            <path
                d="M-0.000244141 24L1.69495 17.8377C0.648893 16.034 0.0992367 13.9872 0.100242 11.8915C0.102251 5.335 5.46316 0 12.05 0C15.2464 0.00100038 18.247 1.23946 20.5029 3.48731C22.7588 5.73515 24.0008 8.72327 23.9998 11.9015C23.9967 18.4579 18.6358 23.7939 12.05 23.7939H12.045C10.0453 23.7939 8.07981 23.2937 6.33437 22.3464L-0.000244141 24ZM6.62779 20.1936L6.99054 20.4077C8.51491 21.308 10.2634 21.7852 12.046 21.7862H12.05C17.5245 21.7862 21.98 17.3515 21.982 11.9015C21.982 9.26047 20.951 6.77654 19.076 4.90884C17.2009 3.04014 14.7068 2.01075 12.054 2.00975C6.57554 2.00975 2.12 6.44342 2.11799 11.8935C2.11799 13.7612 2.64253 15.5798 3.63633 17.1534L3.87247 17.5276L2.86862 21.1749L6.62779 20.1936Z"
                fill="url(#paint1_linear_1018_11610)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.06379 6.92059C8.83971 6.4254 8.60457 6.4154 8.39154 6.40739C8.2177 6.40039 8.01874 6.40039 7.81978 6.40039C7.62082 6.40039 7.29725 6.47442 7.02393 6.77253C6.75061 7.06964 5.97888 7.78891 5.97888 9.25146C5.97888 10.714 7.04905 12.1275 7.19777 12.3256C7.3475 12.5237 9.26376 15.6208 12.2984 16.8123C14.8206 17.8027 15.3341 17.6056 15.8817 17.5556C16.4294 17.5056 17.6483 16.8363 17.8975 16.143C18.1467 15.4488 18.1467 14.8546 18.0713 14.7295C17.997 14.6055 17.798 14.5314 17.4986 14.3824C17.2001 14.2333 15.732 13.5141 15.4587 13.415C15.1854 13.316 14.9854 13.266 14.7865 13.5641C14.5875 13.8612 14.0157 14.5304 13.8409 14.7285C13.667 14.9276 13.4922 14.9516 13.1938 14.8035C12.8953 14.6545 11.9337 14.3414 10.7932 13.328C9.90586 12.5397 9.30596 11.5673 9.13212 11.2692C8.95828 10.9721 9.11303 10.811 9.26275 10.663C9.3964 10.5299 9.5612 10.3159 9.71092 10.1428C9.85964 9.96873 9.90988 9.84568 10.0094 9.64761C10.1088 9.44953 10.0596 9.27547 9.98424 9.12641C9.90988 8.97736 9.32907 7.50781 9.06379 6.91959V6.92059Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1018_11610"
                    x1="12"
                    y1="23.5848"
                    x2="12"
                    y2="0.415138"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#20B038" />
                    <stop offset="1" stopColor="#60D66A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1018_11610"
                    x1="11.9998"
                    y1="24"
                    x2="11.9998"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E2E2E2" />
                    <stop offset="1" stopColor="#FBFBFB" />
                </linearGradient>
            </defs>
        </svg>
    </SvgIcon>
);
