import emojis from 'emojibase-data/en/compact.json';

export interface Emoji {
    label: string;
    hexcode?: string;
    tags?: string[];
    order: number;
    group: number;
    unicode?: string;
}

export const groupedEmojiList = emojis
    .sort((a, b) => a.order - b.order)
    .reduce<Record<number, Emoji[]>>((acc, item) => {
        if (item.group !== null) {
            if (!acc[item.group]) {
                acc[item.group] = [];
            }
            acc[item.group].push(item);
        }

        return acc;
    }, {});
