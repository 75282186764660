const escapeRegex = (string: string): string =>
    string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special regex characters

const prepare = (string: string): string =>
    string.toLowerCase().trim().replace(/\s+/g, ' ');

export const includesPhrase = (string: string, phrase: string): boolean => {
    return new RegExp(`\\b${prepare(escapeRegex(phrase))}\\b`, 'gi').test(
        prepare(string),
    );
};
