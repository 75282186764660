import * as Sentry from '@sentry/react';

export function get(key: string) {
    try {
        const data = window.localStorage.getItem(key);
        return data ? JSON.parse(data) : data;
    } catch (e) {
        Sentry.captureException(e);
    }
}

export function set(key: string, value: unknown) {
    try {
        const data = JSON.stringify(value);
        window.localStorage.setItem(key, data);
    } catch (e) {
        Sentry.captureException(e);
    }
}

export function clear(key: string) {
    try {
        window.localStorage.removeItem(key);
    } catch (e) {
        Sentry.captureException(e);
    }
}

export function parseJwt(
    token: string,
): { teamId: string; userId: string } | null {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
}
