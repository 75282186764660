import { Box, Typography } from '@mui/material';
import { getStepContent, Step } from './utils';
import { useMemo } from 'react';

export const LoginHeader = ({
    step,
    isSignUp = false,
}: {
    step: Step;
    isSignUp?: boolean;
}) => {
    const content = useMemo(
        () => getStepContent(step, isSignUp),
        [step, isSignUp],
    );

    return (
        <div>
            <Box
                display="flex"
                justifyContent="center"
                mb={4}
                fontSize={48}
                height={48}
                lineHeight="48px"
            >
                {content.icon}
            </Box>
            <Typography variant="h1" mb={2} align="center" color="primary.dark">
                {content.header}
            </Typography>
            <Typography
                variant="body3"
                align="center"
                color="primary.dark"
                display="block"
                mb={10}
            >
                {content.subheader}
            </Typography>
        </div>
    );
};
