import { useEffect, useRef } from 'react';
import { Action } from './event-handlers/handle-conversation.updated.event';
import { useSocket } from './index';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';

const subscriptions: Record<string, number> = {};

export const useConversationSubscription = (conversationIds: string[]) => {
    const socket = useSocket().getSocket();
    const prev = useRef<string[]>([]);

    useEffect(() => {
        if (!socket) {
            return;
        }
        const unsubscribe = difference(prev.current, conversationIds);
        const subscribe = difference(conversationIds, prev.current);
        subscribe.forEach((id) => {
            if (!subscriptions[id]) {
                socket?.emit('conversations', {
                    id,
                    act: Action.Subscribe,
                });
                subscriptions[id] = 1;
            } else {
                subscriptions[id] = subscriptions[id] + 1;
            }
        });
        unsubscribe.forEach((id) => {
            subscriptions[id] = subscriptions[id] - 1;
            if (subscriptions[id] <= 0) {
                socket?.emit('conversations', {
                    id,
                    act: Action.Unsubscribe,
                });
                delete subscriptions[id];
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationIds, socket]);

    useEffect(
        () => () => {
            if (!socket) {
                return;
            }
            uniq(conversationIds.concat(prev.current)).forEach((id) => {
                subscriptions[id] = subscriptions[id] - 1;
                if (subscriptions[id] <= 0) {
                    socket.emit('conversations', {
                        id,
                        act: Action.Unsubscribe,
                    });
                    delete subscriptions[id];
                }
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
};
