import { CircularProgress, IconButton, Popover } from '@mui/material';
import { emojiIcon, inboxItemPaper } from './styles';
import EmojiPicker from '../../components/EmojiPicker';
import { renderInboxIcon } from '../../utils/inboxes';
import { Inbox } from '../../api/types';
import { useMenu } from '../../hooks/use-menu';
import { useInboxUpdateQuery } from '../../queries/inboxes';
import { useTrack } from '../../contexts/analytics';
import { useCallback } from 'react';

export function InboxIconSelector({ inbox }: { inbox: Inbox }) {
    const track = useTrack();
    const menu = useMenu();
    const { id, icon, provider } = inbox;
    const { mutateAsync, isPending } = useInboxUpdateQuery();

    const handleEmojiClick = useCallback(
        (val: string) => {
            if (!val) {
                return;
            }

            const hex = val.startsWith('image_')
                ? val
                : val.codePointAt(0)?.toString(16);
            if (!hex) {
                return;
            }

            mutateAsync({ icon: hex, id }).then(() => {
                track('inbox_icon_changed', {
                    inboxId: id,
                    origin: 'sidebar inbox list',
                });
            });

            menu.handleClose();
        },
        [id, mutateAsync, track, menu],
    );

    return (
        <>
            <Popover
                onClose={menu.handleClose}
                transformOrigin={{
                    horizontal: -35,
                    vertical: 'center',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={menu.open}
                anchorEl={menu.anchorEl}
                slotProps={{
                    paper: {
                        sx: inboxItemPaper,
                    },
                }}
            >
                <EmojiPicker withImages onClick={handleEmojiClick} />
            </Popover>

            <IconButton
                color="primary"
                sx={emojiIcon}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    track('inbox_icon_picker_open', {
                        inboxId: id,
                        origin: 'sidebar inbox list',
                    });
                    menu.handleOpen(e);
                }}
            >
                {isPending ? (
                    <CircularProgress size={20} />
                ) : (
                    renderInboxIcon({ icon, provider })
                )}
            </IconButton>
        </>
    );
}
