import { useCallback, useEffect } from 'react';
import * as storage from '../utils/local-storage';
import { getTeamIdFromToken, signOut } from '../api/auth';
import { set } from '../utils/local-storage';
import { queryClient } from '../queries/queryClient';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useMeQuery } from '../queries/user';

export function useMeQueryWithAuthGuard() {
    const meQuery = useMeQuery();
    const { data: me, status, refetch, error, isError } = meQuery;
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const pathParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (
            isError &&
            storage.get('auth') &&
            error?.response?.data?.statusCode === 401
        ) {
            if (
                !getTeamIdFromToken(storage.get('auth')) &&
                !location.pathname.match('/select-team')
            ) {
                navigate('/select-team');
                return;
            }

            if (
                !['/login', '/signup', '/select-team'].includes(
                    location.pathname,
                )
            ) {
                signOut().then(() => {
                    navigate('/login');
                });
            }
        }
    }, [
        error?.response?.data?.statusCode,
        isError,
        location.pathname,
        navigate,
    ]);

    const checkAuth = useCallback(() => {
        const msUserId = searchParams.get('msUserId');
        const msTenantId = searchParams.get('tid');
        const authKey = searchParams.get('k');

        if (msUserId) {
            set('MS_TEAMS_USER_ID', msUserId);
        }

        if (msTenantId) {
            set('MS_TENET_ID', msTenantId);
        }
        if (authKey) {
            set('auth', authKey);
            refetch();
        }
        if (
            status === 'error' &&
            !location.pathname.match('/login') &&
            !location.pathname.match('/zoom-app') &&
            !location.pathname.match('/webex') &&
            !location.pathname.match('/signup') &&
            !location.pathname.match('/forgot') &&
            !location.pathname.match('/select-team')
        ) {
            navigate('/signup');
            queryClient.resetQueries();
            queryClient.clear();
        }

        if (status === 'success') {
            if (!me?.id) {
                if (!location.pathname.startsWith('/login')) {
                    queryClient.resetQueries();
                    queryClient.clear();
                    navigate('/login');
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, me, pathParams, searchParams]);

    useEffect(() => {
        checkAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, status]);

    return meQuery;
}
