import {
    CircularProgress,
    IconButton,
    Popover,
    Stack,
    TextField,
} from '@mui/material';
import EmojiPicker from '../../../../components/EmojiPicker';
import { getSquare } from '../../../../theme/style.helpers';
import { shadows } from '../../../../theme/shadows';
import { renderInboxIcon } from '../../../../utils/inboxes';
import { Inbox } from '../../../../api/types';
import { useTrack } from '../../../../contexts/analytics';
import { useInboxUpdateQuery } from '../../../../queries/inboxes';
import { useMenu } from '../../../../hooks/use-menu';
import debounce from 'lodash/debounce';
import { SettingsRow } from '../../SettingsRow';

export function FormRow({ inbox }: { inbox: Inbox }) {
    const track = useTrack();
    const { mutateAsync, isPending } = useInboxUpdateQuery();
    const menu = useMenu();

    const handleNameHandle = debounce(async (e) => {
        await mutateAsync({
            id: inbox.id,
            name: e.target.value,
        });
    }, 500);

    return (
        <SettingsRow label="Inbox name & icon">
            <Popover
                onClose={menu.handleClose}
                transformOrigin={{
                    horizontal: -35,
                    vertical: 'top',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={menu.open}
                anchorEl={menu.anchorEl}
                slotProps={{
                    paper: {
                        sx: { borderRadius: 2 },
                    },
                }}
            >
                <EmojiPicker
                    onClick={(val) => {
                        if (!val) {
                            return;
                        }
                        const hex = val.codePointAt(0);
                        if (hex) {
                            mutateAsync({
                                icon: hex.toString(16),
                                id: inbox.id,
                            }).then(() => {
                                track('inbox_icon_changed', {
                                    inboxId: inbox.id,
                                    origin: 'sidebar inbox list',
                                });
                            });
                        }
                        menu.handleClose();
                    }}
                />
            </Popover>
            <Stack spacing={4} direction="row" alignItems="center">
                <IconButton
                    sx={{
                        ...getSquare(40),
                        minWidth: 40,
                        boxShadow: shadows[1],
                        border: '1px solid',
                        borderColor: 'custom.gray.outlinedBorder',
                        borderRadius: 1.5,
                        fontSize: 20,
                        color: 'initial',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        menu.handleOpen(e);
                    }}
                >
                    {isPending ? (
                        <CircularProgress size={20} />
                    ) : (
                        renderInboxIcon({
                            icon: inbox.icon,
                            provider: inbox.provider,
                        })
                    )}
                </IconButton>
                <TextField
                    sx={{
                        width: 264,
                    }}
                    defaultValue={inbox.name}
                    onChange={handleNameHandle}
                />
            </Stack>
        </SettingsRow>
    );
}
