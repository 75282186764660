import { useMemo } from 'react';
import { Stack } from '@mui/material';
import sortBy from 'lodash/sortBy';
import { InviteRow } from './InviteRow';
import { useInvites } from '../../../../queries/user';
import { CompactCollapsible } from '../../../../components/CompactCollapsible/CompactCollapsible';

export function Invites({ searchTerm }: { searchTerm?: string }) {
    const { data: invites = [] } = useInvites();

    const filteredInvites = useMemo(() => {
        const filtered = searchTerm
            ? invites.filter(
                  ({ email, phone }) =>
                      email?.toLocaleLowerCase().includes(searchTerm) ||
                      phone?.includes(searchTerm),
              )
            : invites;
        return sortBy(filtered, ['email', 'phone']);
    }, [invites, searchTerm]);

    return (
        filteredInvites.length > 0 && (
            <CompactCollapsible
                label="Pending invitations"
                count={filteredInvites.length}
                autoExpand
            >
                <Stack spacing={2}>
                    {filteredInvites.map((invite) => (
                        <InviteRow key={invite.id} invite={invite} />
                    ))}
                </Stack>
            </CompactCollapsible>
        )
    );
}
