import { Contact, Feature, Profile } from '../api/types';
import { CONTACT } from '../queries/contacts';
import { queryClient } from '../queries/queryClient';
import { CONTACT_BY_PHONE_KEY } from '../components/Contacts/use-contact-by-phone';
import { CURRENT_USER_KEY } from '../queries/user';

export const CONTACT_CREATED_EVENT = 'contact:created';

type DataPayload = Contact;

export const handleContactCreatedEvent = async (data: DataPayload) => {
    const currentUserId = queryClient.getQueryData<Profile>([
        CURRENT_USER_KEY,
    ])?.id;
    await queryClient.refetchQueries({ queryKey: [CONTACT, data.id] });
    if ((data.public || data.createdByUserId === currentUserId) && data.phone) {
        const me = queryClient.getQueryData<Profile>([CURRENT_USER_KEY])!;
        const isMultipleContactPhonesEnabled =
            me?.features.some(
                (f) => f.name === Feature.MultipleContactPhones && f.enabled,
            ) || false;

        queryClient.setQueryData<Contact>(
            [CONTACT_BY_PHONE_KEY, data.phone, isMultipleContactPhonesEnabled],
            data,
        );
    }
};
