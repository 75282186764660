import { ClerkIconProps } from '../../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const SmileIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
                fill="#667085"
            />
            <path
                d="M16.9999 9.63636V10.4545H13.6685V9.63636C13.6685 9.20237 13.844 8.78616 14.1564 8.47928C14.4688 8.1724 14.8924 8 15.3342 8C15.776 8 16.1996 8.1724 16.512 8.47928C16.8244 8.78616 16.9999 9.20237 16.9999 9.63636ZM11.997 13.7273C13.6952 13.6845 15.3791 13.4091 16.9999 12.9091C16.4877 14.7745 14.7812 17 12.0028 17C9.22443 17 7.5121 14.7745 6.9999 12.9091C8.61859 13.4095 10.3006 13.6849 11.997 13.7273Z"
                fill="#667085"
            />
            <path
                d="M10.3116 10.4545V9.63636C10.3116 9.20237 10.1361 8.78616 9.82374 8.47928C9.51136 8.1724 9.08769 8 8.64592 8C8.20415 8 7.78047 8.1724 7.4681 8.47928C7.15572 8.78616 6.98022 9.20237 6.98022 9.63636V10.4545H10.3116Z"
                fill="#667085"
            />
        </svg>
    </SvgIcon>
);
