import { RequestableTeam, TeamForAuth } from '../../api/types';
import { Avatar, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { avatar, limitedName } from './styles';
import { DEFAULT_TEAM_NAME } from './constants';
import logo from '../../elements/IconComponents/clerk-logo-header.svg';

type Props = {
    team: TeamForAuth | RequestableTeam;
    onClick: () => void;
    secondaryText?: string;
    selected?: boolean;
};

export function TeamItem({
    team,
    onClick,
    secondaryText,
    selected = false,
}: Props) {
    return (
        <MenuItem
            key={team.id}
            selected={selected}
            onClick={onClick}
            sx={{ overflow: 'hidden' }}
            data-navigation-element
            tabIndex={0}
        >
            <ListItemIcon>
                <Avatar
                    sx={avatar}
                    alt={team?.name || DEFAULT_TEAM_NAME}
                    src={team?.teamAvatarURL || logo}
                />
            </ListItemIcon>

            <ListItemText
                primaryTypographyProps={{
                    sx: !secondaryText ? limitedName : undefined,
                    noWrap: !!secondaryText,
                }}
                secondaryTypographyProps={{
                    color: 'custom.gray.super',
                }}
                secondary={secondaryText}
            >
                {team.name ?? 'Team'}
            </ListItemText>
        </MenuItem>
    );
}
