import { ClerkIconProps } from '../../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const BulbIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3.75C8.54822 3.75 5.75 6.54822 5.75 10C5.75 12.2 6.8863 14.1354 8.60729 15.25H15.3927C17.1137 14.1354 18.25 12.2 18.25 10C18.25 6.54822 15.4518 3.75 12 3.75ZM15.75 16.7364C15.8361 16.7197 15.9189 16.688 15.9947 16.6424C18.2433 15.2874 19.75 12.82 19.75 10C19.75 5.71979 16.2802 2.25 12 2.25C7.71979 2.25 4.25 5.71979 4.25 10C4.25 12.82 5.75666 15.2874 8.00533 16.6424C8.08106 16.688 8.16392 16.7197 8.25 16.7364L8.25 18.0253C8.24999 18.4697 8.24999 18.8408 8.27077 19.1454C8.29241 19.4625 8.33905 19.762 8.45933 20.0524C8.73844 20.7262 9.27379 21.2616 9.94762 21.5407C10.238 21.661 10.5375 21.7076 10.8546 21.7292C11.1592 21.75 11.5303 21.75 11.9747 21.75H12.0253C12.4697 21.75 12.8408 21.75 13.1454 21.7292C13.4625 21.7076 13.762 21.661 14.0524 21.5407C14.7262 21.2616 15.2616 20.7262 15.5407 20.0524C15.661 19.762 15.7076 19.4625 15.7292 19.1454C15.75 18.8408 15.75 18.4697 15.75 18.0253V16.7364ZM14.25 16.75H9.75V18C9.75 18.4762 9.75041 18.7958 9.76729 19.0433C9.78372 19.284 9.81319 19.4012 9.84515 19.4784C9.97202 19.7846 10.2154 20.028 10.5216 20.1549C10.5988 20.1868 10.716 20.2163 10.9567 20.2327C11.2042 20.2496 11.5238 20.25 12 20.25C12.4762 20.25 12.7958 20.2496 13.0433 20.2327C13.284 20.2163 13.4012 20.1868 13.4784 20.1549C13.7846 20.028 14.028 19.7846 14.1548 19.4784C14.1868 19.4012 14.2163 19.284 14.2327 19.0433C14.2496 18.7958 14.25 18.4762 14.25 18V16.75Z"
                fill="#667085"
            />
        </svg>
    </SvgIcon>
);
