import { ToastActions, useToastContext } from '../../containers/toast/reducer';
import * as Sentry from '@sentry/react';
import { useRequestToJoinTeam } from '../../queries/team';
import { RequestableTeam } from '../../api/types';
import { TeamItem } from './TeamItem';

type Props = {
    team: RequestableTeam;
    onClick: () => void;
};

export function RequestToJoinTeamItem({ team, onClick }: Props) {
    const { mutateAsync: requestToJoinTeam } = useRequestToJoinTeam();
    const { dispatch: toastDispatch } = useToastContext();

    return (
        <TeamItem
            team={team}
            onClick={() => {
                requestToJoinTeam(team.id)
                    .then(() => {
                        toastDispatch({
                            type: ToastActions.ADD,
                            payload: {
                                icon: '✅',
                                severity: 'success',
                                title: 'The request has been sent',
                                description:
                                    'As soon as someone has approved your request, you will be able to join a workspace.',
                            },
                        });
                    })
                    .catch((error) => {
                        Sentry.captureException(error);
                        toastDispatch({
                            type: ToastActions.ADD,
                            payload: {
                                severity: 'error',
                                title: 'Failed to request to join team',
                                description: 'Please try again later.',
                            },
                        });
                    });
                onClick();
            }}
            secondaryText="Request access to a team"
        />
    );
}
