import { ClerkIconProps } from '../../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const NatureIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.85042 21.9871C2.46418 22.0605 2.08936 21.8142 2.01325 21.437C1.9381 21.0598 2.1902 20.6928 2.57644 20.6194C2.57644 20.6194 9.52877 19.2638 14.4785 11.6386C14.6888 11.3143 15.1302 11.2177 15.4632 11.424C15.7952 11.6293 15.8941 12.0604 15.6839 12.3856C10.3631 20.5813 2.85042 21.9871 2.85042 21.9871Z"
                fill="#667085"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.6848 9.3736C9.8627 9.71644 9.72286 10.1364 9.37182 10.3111C9.02078 10.4848 8.59077 10.3482 8.41192 10.0054C7.98858 9.19056 7.60044 8.32835 7.26747 7.42339C7.13429 7.06103 7.32741 6.66244 7.69842 6.53237C8.06849 6.40229 8.47756 6.5909 8.61075 6.95233C8.92279 7.80154 9.28715 8.60987 9.6848 9.3736Z"
                fill="#667085"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.4785 4.40161C21.4785 4.40161 22.3585 7.64515 21.8324 11.2659C21.4738 13.7355 20.4625 16.3695 18.1356 18.3114C15.6935 20.3499 11.7664 21.6497 5.49999 21.0681C5.15371 21.0356 4.88067 20.7633 4.85594 20.4242C4.85594 20.4242 4.30322 12.8212 9.82855 7.90716C12.184 5.81108 15.645 4.18884 20.7451 3.88316C21.0847 3.86272 21.392 4.08014 21.4785 4.40161ZM20.2256 5.31865C15.8695 5.68379 12.8595 7.09604 10.7894 8.93755C6.6492 12.6196 6.26962 17.9658 6.26486 19.7329C11.6313 20.1306 15.059 19.0463 17.209 17.2522C19.2458 15.5519 20.1048 13.2328 20.4197 11.0699C20.7774 8.60864 20.4311 6.33603 20.2256 5.31865Z"
                fill="#667085"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9403 6.47177C13.1828 6.77466 13.1286 7.21227 12.8194 7.45012C12.5093 7.68798 12.0603 7.63502 11.8177 7.33213C10.5914 5.80652 8.70112 4.44723 5.84523 3.54134C5.55222 4.21309 5.07655 5.46739 4.8568 6.94933C4.53905 9.09559 4.75405 11.7445 6.99634 13.717C7.28935 13.9744 7.31218 14.4148 7.04866 14.7009C6.78514 14.9871 6.33421 15.0094 6.0412 14.752C3.38414 12.4144 3.06829 9.29442 3.44502 6.74957C3.81033 4.27906 4.82445 2.37623 4.82445 2.37623C4.98047 2.08077 5.32866 1.9349 5.65591 2.02781C9.20343 3.03032 11.4838 4.65906 12.9403 6.47177Z"
                fill="#667085"
            />
        </svg>
    </SvgIcon>
);
