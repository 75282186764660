import * as styles from '../styles';
import { ValueProps } from '../../types';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { EmptyValuePlaceholder } from '../../EmptyValuePlaceholder';
import { CopyAction } from './CopyAction';

export const LimitedTextValue = ({
    userProperty,
    onChange,
    formatDisplay,
    validate,
    readonly = false,
    children,
}: PropsWithChildren<ValueProps<string>>) => {
    const [value, setValue] = useState(userProperty.value);
    const [isEditEnabled, setEditEnabled] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string | null>(null);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        setError(null);
        setValue(userProperty.value);
    }, [userProperty]);

    if (isEditEnabled) {
        const handleLooseFocus = () => {
            const error = validate?.({ ...userProperty, value }) ?? null;
            if (error !== null) {
                setError(error);
                return;
            }
            onChange?.({ ...userProperty, value });
            setEditEnabled(false);
        };

        return (
            <TextField
                inputProps={{
                    'aria-label': `${userProperty.name} value input`,
                }}
                onBlur={handleLooseFocus}
                onKeyDown={(e) => {
                    switch (e.key) {
                        case 'Enter':
                            handleLooseFocus();
                            break;
                        case 'Escape':
                            setError(null);
                            setValue(userProperty.value);
                            setEditEnabled(false);
                            break;
                        default:
                            break;
                    }
                }}
                error={!!error}
                helperText={error}
                autoFocus
                size="small"
                variant="outlined"
                value={value}
                ref={inputRef}
                fullWidth
                onChange={(e) => {
                    setValue(e.target.value);
                    setError(null);
                }}
            />
        );
    }

    return (
        <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={readonly ? styles.readOnly : styles.limitedValue}
            title={userProperty.value}
            onClick={() => !readonly && setEditEnabled(true)}
        >
            {userProperty.value ? (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    maxWidth={1}
                    flex={1}
                >
                    <Typography
                        aria-label={`${userProperty.name} set a value`}
                        variant="body3"
                        color="primary.dark"
                        noWrap
                        sx={{
                            display: 'inline-block',
                        }}
                    >
                        {formatDisplay
                            ? formatDisplay(userProperty.value)
                            : userProperty.value}
                    </Typography>
                    {isHovered && (
                        <Box>
                            {children || (
                                <CopyAction value={userProperty.value} />
                            )}
                        </Box>
                    )}
                </Stack>
            ) : (
                <EmptyValuePlaceholder
                    aria-label={`${userProperty.name} set a value`}
                />
            )}
        </Box>
    );
};
