import { ClerkIconProps } from '../../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const RCSIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                d="M7.71905 15.1429C4.0046 15.1429 1 11.9733 1 8.0714C1 4.16949 4.0046 1 7.71905 1H14.7096C18.424 1 21.4286 4.1696 21.4286 8.0714C21.4286 11.9732 18.424 15.1428 14.7096 15.1428L7.71905 15.1429Z"
                fill="#86A9FF"
            />
            <path
                d="M12.2499 18.0005V22.3125C12.2499 22.6877 11.9478 23 11.5856 23C11.4083 22.9988 11.2393 22.9254 11.1173 22.7969L4.56436 16.0476C3.28091 14.7355 2.57141 12.9389 2.57141 11.0795C2.57141 7.25181 5.57601 4.14282 9.29046 4.14282H16.281C19.9954 4.14282 23 7.25193 23 11.0795C23 14.907 19.9954 18.0161 16.281 18.0161H12.2499V18.0005Z"
                fill="#578CFF"
            />
            <path
                d="M7.68641 15.1429H14.6938C18.4169 15.1429 21.4285 11.8998 21.4285 7.9073C21.4285 7.43464 21.3829 6.9786 21.3075 6.5221C20.0818 5.05555 18.2959 4.14301 16.3132 4.14301H9.30616C5.583 4.14301 2.57141 7.38606 2.57141 11.3786C2.57141 11.8513 2.61702 12.3073 2.69241 12.7473C3.91811 14.2139 5.704 15.1429 7.68675 15.1429H7.68641Z"
                fill="#0057CC"
            />
        </svg>
    </SvgIcon>
);
