import { setAuthToStorage } from '../../api/team';
import { queryClient } from '../../queries/queryClient';
import * as Sentry from '@sentry/react';
import { ToastActions, useToastContext } from '../../containers/toast/reducer';
import { TeamForAuth } from '../../api/types';
import { useJoinTeam } from '../../queries/team';
import { useNavigate } from 'react-router';
import { TeamItem } from './TeamItem';

type Props = {
    team: TeamForAuth;
    onClick: () => void;
};

export function JoinTeamItem({ team, onClick }: Props) {
    const navigate = useNavigate();
    const { mutateAsync: joinTeam } = useJoinTeam();
    const { dispatch: toastDispatch } = useToastContext();

    return (
        <TeamItem
            team={team}
            onClick={() => {
                joinTeam(team.id)
                    .then((data) => {
                        setAuthToStorage(data);
                        navigate('/');
                        queryClient.resetQueries();
                    })
                    .catch((error) => {
                        Sentry.captureException(error);
                        toastDispatch({
                            type: ToastActions.ADD,
                            payload: {
                                severity: 'error',
                                title: 'Failed to join a team',
                                description: 'Please try again later.',
                            },
                        });
                    });
                onClick();
            }}
            secondaryText="Available to join"
        />
    );
}
