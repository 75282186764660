import React, {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import {
    Autocomplete,
    Chip,
    InputAdornment,
    Paper,
    Popper,
    PopperProps,
    TextField,
    Typography,
} from '@mui/material';
import { Contact, ContactPhone, Feature } from '../../../api/types';
import {
    createContactFromContactPhoneNumber,
    createContactFromPhoneNumber,
} from '../utils/createContactFromPhoneNumber';
import { paper, tag, textField, textInput } from './styles';
import { Legend } from './Legend';
import { CohortMetaDto } from '../../../api/cohorts';
import { AnyContact, ConversationOption } from '../types';
import {
    isCohort,
    isContact,
    isContactPhone,
    isCustomPhone,
} from '../utils/options';
import { findAndFormatPhonesInText } from '../../../utils/phoneNumber';
import { ContactMenuItem } from './ContactMenuItem';
import { CohortMenuItem } from './CohortMenuItem';
import { Group } from './Group';
import { useOptions } from './hooks/useOptions';
import { ContactPhoneMenuItem } from './ContactPhoneMenuItem';
import {
    useEnabledFeature,
    usePhoneNumberFormatter,
} from '../../../queries/user';
import { getNotSelectedPhone, getPrimaryPhone } from '../utils';
import uniqBy from 'lodash/uniqBy';
import { getContactName } from '../../../utils/contacts';
import { CustomPhoneMenuItem } from './CustomPhoneMenuItem';

interface Props {
    isLimitReached: boolean;
    selectedContacts: AnyContact[];
    setSelectedContacts: Dispatch<SetStateAction<AnyContact[]>>;
    selectedCohorts: CohortMetaDto[];
    setSelectedCohorts: (cohorts: CohortMetaDto[]) => void;
}

export const SearchPanel = ({
    isLimitReached,
    selectedContacts,
    setSelectedContacts,
    selectedCohorts,
    setSelectedCohorts,
}: PropsWithChildren<Props>) => {
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );
    const inputRef = useRef<HTMLDivElement>(null);
    const popperRef = useRef<HTMLDivElement>(null);
    const [quickSearchTerm, setQuickSearchTerm] = useState('');
    const [expandedContact, setExpandedContact] = useState<Contact | null>(
        null,
    );

    const { options, isSearchFetching, isSomeFilteredOptionsExist } =
        useOptions(
            quickSearchTerm,
            isLimitReached,
            selectedContacts,
            selectedCohorts,
        );
    const format = usePhoneNumberFormatter();

    useEffect(() => {
        inputRef?.current && inputRef?.current.focus();
    }, [inputRef]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearchInput = useCallback(
        debounce((searchTerm: string) => {
            return setQuickSearchTerm(searchTerm);
        }, 300),
        [],
    );

    const handleChange = (selectedOptions: ConversationOption[]) => {
        setQuickSearchTerm('');
        setExpandedContact(null);
        const [contacts, cohorts] = selectedOptions.reduce(
            ([contacts, cohorts]: [AnyContact[], CohortMetaDto[]], option) => {
                if (isCohort(option)) {
                    return [contacts, [...cohorts, option]];
                }

                if (isMultipleContactPhonesEnabled && isContact(option)) {
                    const primary = getPrimaryPhone(
                        getNotSelectedPhone(option?.phones ?? [], contacts),
                    );

                    if (primary) {
                        return [
                            [
                                ...contacts,
                                createContactFromContactPhoneNumber(
                                    option,
                                    primary,
                                ),
                            ],
                            cohorts,
                        ];
                    }
                }

                return [[...contacts, option], cohorts];
            },
            [[], []],
        );
        setSelectedContacts(uniqBy(contacts, 'id'));
        setSelectedCohorts(cohorts);
    };

    const handlePasteEvent = useCallback(
        (e: React.ClipboardEvent) => {
            const value = e.clipboardData?.getData('text');
            e.preventDefault();
            const phoneObjects = findAndFormatPhonesInText(value).map(
                createContactFromPhoneNumber,
            );
            setSelectedContacts((prev) => [...prev, ...phoneObjects]);
            setQuickSearchTerm('');
        },
        [setSelectedContacts],
    );

    const handleSelectExtraPhoneNumber = useCallback(
        (contact: Contact, selectedPhone: ContactPhone) => {
            setSelectedContacts([
                ...selectedContacts,
                createContactFromContactPhoneNumber(contact, selectedPhone),
            ]);
        },
        [selectedContacts, setSelectedContacts],
    );

    const value = [...selectedContacts, ...selectedCohorts];

    return (
        <div data-testid="recipients-search-panel">
            <Autocomplete
                multiple
                value={value}
                options={options}
                loading={isSearchFetching}
                clearOnBlur={false}
                autoHighlight
                fullWidth
                disableClearable
                disableCloseOnSelect
                disabled={isLimitReached}
                filterSelectedOptions
                onKeyDown={(e) => {
                    if (
                        ['ArrowDown', 'ArrowUp'].includes(e.key) &&
                        quickSearchTerm.length
                    ) {
                        e.stopPropagation();
                    }

                    if (
                        e.key === 'Enter' &&
                        (!quickSearchTerm.length || !isSomeFilteredOptionsExist)
                    ) {
                        e.stopPropagation();
                    }
                }}
                noOptionsText="No contacts found"
                isOptionEqualToValue={(option, value) =>
                    (option?.id === value?.id && !!option?.id) ||
                    (option?.name === value?.name && !!option?.name)
                }
                groupBy={(option) => (isCohort(option) ? 'Lists' : 'Contacts')}
                renderGroup={Group}
                filterOptions={(options) => {
                    const selectedContactsIds = selectedContacts.map(
                        (c) => c.id,
                    );

                    return options.filter((option) => {
                        if (
                            isMultipleContactPhonesEnabled &&
                            isContact(option)
                        ) {
                            return !(option.phones ?? []).every((phone) =>
                                selectedContactsIds.includes(phone.id),
                            );
                        }

                        return true;
                    });
                }}
                slotProps={{
                    paper: {
                        sx: paper,
                    },
                }}
                getOptionLabel={(option: ConversationOption) =>
                    isCohort(option)
                        ? option.name
                        : (getContactName(option) ?? '')
                }
                renderTags={(
                    value: readonly ConversationOption[],
                    getTagProps,
                ) =>
                    value.length > 10 ? (
                        <Chip
                            variant="filled"
                            size="small"
                            color="success"
                            label={`${value.length} Contacts Selected`}
                            sx={tag}
                            onDelete={() => setSelectedContacts([])}
                        />
                    ) : (
                        value.map(
                            (option: ConversationOption, index: number) => {
                                const formattedPhone =
                                    'phone' in option
                                        ? format(option.phone ?? '')
                                        : '';

                                const email =
                                    'email' in option
                                        ? (option.email?.trim() ?? '')
                                        : '';

                                const label = isCohort(option)
                                    ? option.name
                                    : option.name?.trim() ||
                                      email ||
                                      formattedPhone;

                                return (
                                    <Chip
                                        variant="filled"
                                        size="small"
                                        color="success"
                                        label={label}
                                        sx={tag}
                                        key={getTagProps({ index }).key}
                                        {...omit(getTagProps({ index }), [
                                            'key',
                                            'disabled',
                                        ])}
                                    />
                                );
                            },
                        )
                    )
                }
                onChange={(_, newValue) => {
                    newValue && handleChange(newValue);
                }}
                onInputChange={(e) => {
                    e?.type === 'change' &&
                        handleSearchInput(
                            (e?.target as HTMLInputElement).value ?? '',
                        );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputRef={inputRef}
                        variant="filled"
                        sx={textField}
                        placeholder="Enter a phone, name or paste a list of phone numbers"
                        InputProps={{
                            ...params.InputProps,
                            onPaste: handlePasteEvent,
                            sx: textInput,
                            inputProps: {
                                ...params.inputProps,
                                tabIndex: 0,
                                ['data-navigation-element']: true,
                            },
                            startAdornment: (
                                <>
                                    <InputAdornment
                                        position="end"
                                        sx={{ mr: 1 }}
                                    >
                                        <Typography
                                            variant="body3"
                                            color="text.primary"
                                        >
                                            To:
                                        </Typography>
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                            endAdornment: null,
                        }}
                    />
                )}
                PopperComponent={(props: PopperProps) => {
                    return (
                        isSomeFilteredOptionsExist &&
                        !!quickSearchTerm.length && (
                            <Popper
                                {...props}
                                modifiers={[
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [20, -12],
                                        },
                                    },
                                ]}
                                style={{ width: 348 }}
                                placement="bottom-start"
                                popperOptions={{ placement: 'bottom-start' }}
                                data-testid="search-panel-popper"
                                ref={popperRef}
                            >
                                <Paper elevation={5}>
                                    <>
                                        {props.children}
                                        <Legend />
                                    </>
                                </Paper>
                            </Popper>
                        )
                    );
                }}
                renderOption={(props, option: ConversationOption) => {
                    switch (true) {
                        case isCohort(option):
                            return (
                                <CohortMenuItem
                                    key={option.id}
                                    cohort={option}
                                    props={props}
                                />
                            );
                        case isContact(option): {
                            return (
                                <ContactMenuItem
                                    key={option.id}
                                    notSelectedPhones={
                                        isMultipleContactPhonesEnabled
                                            ? getNotSelectedPhone(
                                                  option?.phones ?? [],
                                                  selectedContacts,
                                              )
                                            : []
                                    }
                                    expanded={expandedContact?.id === option.id}
                                    setExpanded={setExpandedContact}
                                    contact={option}
                                    props={props}
                                />
                            );
                        }
                        case isContactPhone(option) &&
                            expandedContact?.id === option.contactId:
                            return (
                                <ContactPhoneMenuItem
                                    key={option.id}
                                    contactPhone={option}
                                    props={props}
                                    handleSelectExtraPhoneNumber={
                                        handleSelectExtraPhoneNumber
                                    }
                                />
                            );
                        case isCustomPhone(option):
                            return (
                                <CustomPhoneMenuItem
                                    key={option.id}
                                    customPhone={option}
                                    props={props}
                                />
                            );
                        default:
                            return null;
                    }
                }}
            />
        </div>
    );
};
