import React, { useState, createContext, useContext, useEffect } from 'react';
import { TeamsPhone } from '../../../api/types';
import {
    useCarrierCheck,
    useCheckHostabilityQuery,
} from '../../../queries/inboxes';
import { getNextAvailableDate, getNextAvailableDateInstant } from './utils';
import dayjs from 'dayjs';

interface SetupMicrosoftTeamsInboxContextProps {
    selectedPhones: TeamsPhone[];
    setSelectedPhones: React.Dispatch<React.SetStateAction<TeamsPhone[]>>;
    setActivationDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
    activationDate: Date | undefined;
    activateInstantly: boolean;
    setActivateInstantly: React.Dispatch<React.SetStateAction<boolean>>;
    isHostable: boolean;
    carriersMatchBandwidth: boolean;
}

const SetupMicrosoftTeamsInboxContext = createContext<
    SetupMicrosoftTeamsInboxContextProps | undefined
>(undefined);

export const SetupMicrosoftTeamsInboxProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [selectedPhones, setSelectedPhones] = useState<TeamsPhone[]>([]);
    const [activationDate, setActivationDate] = useState<Date | undefined>();
    const [activateInstantly, setActivateInstantly] = useState(false);

    const { data: hostable } = useCheckHostabilityQuery(
        selectedPhones.map((phone) => phone.phone),
        selectedPhones.length > 0,
    );

    const { data: carriersMatchBandwidth } = useCarrierCheck(
        selectedPhones.map((phone) => phone.phone),
        'BANDWIDTH',
    );

    useEffect(() => {
        if (selectedPhones.length > 0 && (hostable || carriersMatchBandwidth)) {
            setActivateInstantly(true);
            setActivationDate(
                getNextAvailableDateInstant(
                    dayjs().add(15, 'minutes'),
                ).toDate(),
            );
        } else {
            setActivateInstantly(false);
            setActivationDate(getNextAvailableDate().toDate());
        }
    }, [hostable, selectedPhones.length, carriersMatchBandwidth]);

    return (
        <SetupMicrosoftTeamsInboxContext.Provider
            value={{
                selectedPhones,
                setSelectedPhones,
                setActivationDate,
                activationDate,
                activateInstantly,
                setActivateInstantly,
                isHostable: hostable ?? false,
                carriersMatchBandwidth,
            }}
        >
            {children}
        </SetupMicrosoftTeamsInboxContext.Provider>
    );
};

export const useSetupMicrosoftTeamsInboxContext = () => {
    const context = useContext(SetupMicrosoftTeamsInboxContext);
    if (!context) {
        throw new Error('useInboxContext must be used within an InboxProvider');
    }
    return context;
};
