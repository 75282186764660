import { ClerkIconProps } from '../../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const SymbolIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.4465 4.38841C15.6599 4.01663 16.1318 3.88958 16.5006 4.10465C19.1887 5.67227 21 8.60418 21 11.9635C21 15.3229 19.1887 18.2548 16.5006 19.8224C16.2011 19.997 15.8228 19.9493 15.5753 19.7056L9.40388 13.6298C9.09907 13.3297 9.09328 12.8373 9.39095 12.5301C9.68861 12.2228 10.177 12.2169 10.4818 12.517L16.2179 18.1642C18.1755 16.8081 19.4571 14.5363 19.4571 11.9635C19.4571 9.18186 17.959 6.75204 15.728 5.45101C15.3592 5.23594 15.2332 4.7602 15.4465 4.38841ZM7.49944 4.17761C7.80041 4.00209 8.18066 4.05125 8.42797 4.29765L14.5994 10.4463C14.9024 10.7483 14.9053 11.2407 14.6058 11.5462C14.3064 11.8517 13.8179 11.8546 13.5149 11.5526L7.77907 5.83795C5.82314 7.19428 4.54286 9.46504 4.54286 12.0365C4.54286 14.8181 6.04103 17.248 8.27199 18.549C8.64078 18.7641 8.7668 19.2398 8.55347 19.6116C8.34013 19.9834 7.86823 20.1104 7.49944 19.8954C4.81135 18.3277 3 15.3958 3 12.0365C3 8.67715 4.81135 5.74523 7.49944 4.17761Z"
                fill="#667085"
            />
        </svg>
    </SvgIcon>
);
