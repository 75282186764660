import * as storage from '../utils/local-storage';
import { LoginCredentials, Verification } from './types';
import client from './http';
import { queryClient } from '../queries/queryClient';
import { CURRENT_USER_KEY } from '../queries/user';

export type SendOtpResp = {
    email_id: string;
};

export function sendOTP(dto: LoginCredentials) {
    return client()
        .post<SendOtpResp>('/auth/otp/send', dto)
        .then(({ data }) => data);
}

type VerifyOtpResp = {
    access_token: string;
};

export function verifyOTP(dto: Verification) {
    return client()
        .post<VerifyOtpResp>('/auth/otp/verify', dto)
        .then(({ data }) => {
            storage.set('auth', data.access_token);
            const { userId, teamId } =
                storage.parseJwt(data.access_token) ?? {};
            if (teamId) {
                queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
            }
            return userId;
        });
}

export function getTeamIdFromToken(token: string): string | null {
    return storage.parseJwt(token)?.teamId ?? null;
}

export async function signOut() {
    storage.clear('auth');

    queryClient.cancelQueries();

    await queryClient.resetQueries();
    await queryClient.invalidateQueries({
        queryKey: [CURRENT_USER_KEY],
    });
    queryClient.clear();
    return Promise.resolve();
}

export async function signInViaTeams(ssoToken: string) {
    const response = await client().post(
        `/auth/microsoft/obo?ssoToken=${ssoToken}`,
    );
    if (response.status < 300 && response.data) {
        storage.set('auth', response.data.access_token);
        storage.set('msOauthToken', response.data.ms_oauth_token);
    }
}
