import { SxProps } from '@mui/material';
import { getSquare } from '../../theme/style.helpers';
import { colors } from '../../theme/palette';

export const emojiButton: SxProps = {
    borderRadius: 1,
    height: 35,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
        bgcolor: colors.action.hover,
    },
};
export const emojiSpan: SxProps = {
    fontSize: 25,
    lineHeight: 1.4,
    transform: 'all 0.2s',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
};

export const clerkEmojiPicker: SxProps = {
    maxWidth: '364px',
    width: '100vw',
    height: '478px',
    overflow: 'hidden',
    bgcolor: 'white',
    borderRadius: '8px',
    boxShadow: '0 10px 25px rgba(39, 52, 68, 0.05)',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: 'custom.gray.tableDivider',
};

export const tabs: SxProps = {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderColor: 'custom.gray.tableDivider',
    gap: 0.5,
    px: 3,
};

export const tab: SxProps = {
    p: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    minWidth: '32px',
    height: '40px',
    fontSize: '22px',
    cursor: 'pointer',

    '& svg': {
        ...getSquare(24),
    },

    '&:hover': {
        bgcolor: 'inherit',
        '&:before': {
            opacity: 0.2,
            borderBottom: '2px solid',
            borderColor: 'info.main',
        },
    },

    '&:before': {
        content: '" "',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.2,
        transition: '0.2s all',
        borderBottom: '2px solid transparent',
        zIndex: 1,
    },
};

export const tabIcon: SxProps = {
    width: '25px',
};

export const activeTab: SxProps = {
    cursor: 'default',
    '&:before': {
        opacity: 1,
        borderBottom: '2px solid',
        borderColor: 'info.main',
    },

    '&:hover': {
        '&:before': {
            opacity: 1,
            borderBottom: '2px solid',
            borderColor: 'info.main',
        },
    },
};

export const listWrapper: SxProps = {
    px: 3,
    py: 4,
    pb: 0,
};

export const section: SxProps = {
    maxHeight: '387px',
    overflow: 'auto',
    scrollbarWidth: 'none',
    position: 'relative',
    msOverflowStyle: 'none',
    bgcolor: 'white',
    '&::-webkit-scrollbar': {
        width: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        backgroundClip: 'padding-box',
        border: '3px solid transparent',
        borderRadius: '12px',
    },
};

export const titleBackground: SxProps = {
    position: 'absolute',
    top: 50,
    left: 0,
    right: 0,
    height: '45px',
    opacity: 1,
    zIndex: 9,
    marginRight: '10px',
    backgroundColor: '#fff',
};

export const sectionTitle: SxProps = {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    padding: '16px 0 8px 0',

    backgroundColor: '#fff',
    color: colors.text.secondary,
    fontSize: '12px',
    lineHeight: '16px',
};
