import { Box } from '@mui/material';
import { PlanCard, PlanCardType } from './PlanCard';
import { useMemo, useState } from 'react';
import { planList } from '../../../containers/PaymentFlow/plans';
import { StripeMainSubscription } from '../../../api/billing';
import { ChangePeriodModal } from './ChangePeriodModal';
import dayjs from 'dayjs';
import { useLicenseCount } from '../../../hooks/use-license-count';

const billingCycleMap = {
    month: 'Monthly',
    year: 'Yearly',
    day: 'Daily',
    week: 'Weekly',
};

export const Period = ({
    subscription,
    disabled,
}: {
    subscription?: StripeMainSubscription;
    disabled: boolean;
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const licenseCount = useLicenseCount();

    const plan = useMemo(
        () => planList.find((plan) => plan.priceId === subscription?.planId),
        [subscription?.planId],
    );
    if (!subscription || !plan) {
        return (
            <PlanCard
                onChange={() => setIsModalOpen(true)}
                title="Billing period"
                subtitle="—"
                disabled
                cardType={PlanCardType.Period}
            />
        );
    }
    return (
        <>
            <PlanCard
                onChange={() => setIsModalOpen(true)}
                title="Billing period"
                subtitle={
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <span>{`${billingCycleMap[subscription.interval]} (renews ${dayjs(subscription.currentPeriodEnd * 1000).format('MMMM DD, YYYY')})`}</span>
                    </Box>
                }
                disabled={disabled}
                cardType={PlanCardType.Period}
            />
            {isModalOpen && (
                <ChangePeriodModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSave={() => setIsModalOpen(false)}
                    licenseCount={licenseCount}
                    activePlan={plan}
                    subscription={subscription}
                />
            )}
        </>
    );
};
