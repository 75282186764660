import { useMessageCommentCreate } from '../../queries/comments';
import { MessageInput } from '../MessageInput/MessageInput';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { createEditor } from 'slate';
import { MessageFormData } from '../MessageForm';
import { withParagraphs } from '../MessageInput/components/Paragraph/withParagraph';
import { useState } from 'react';

type Props = {
    discussionId: number;
};

export const DiscussionMessageBox = (props: Props) => {
    const { mutate: createComment } = useMessageCommentCreate();
    const [editor] = useState(
        withParagraphs(withReact(withHistory(createEditor()))),
    );

    const onSubmit = ({ message, files }: MessageFormData) => {
        if (message || (Array.isArray(files) && files?.length > 0)) {
            createComment({
                discussionId: props.discussionId,
                text: message,
                fileIds: files?.map((f) => f.id),
            });
        }
    };

    return (
        <MessageInput
            editor={editor}
            placeholder="Write a message..."
            onSend={onSubmit}
            toolbar={['emojiPicker']}
            withSendButton
            autoFocus
        />
    );
};
