import { lazy, Suspense } from 'react';
import { Box } from '@mui/material';
import { sectionTitle } from './styles';
import { EmojiTab, EmojiWithImages } from './types';

const EmojiList = lazy(() => import('./EmojiList'));

type EmojiGroupListProps = {
    tabIndex: number;
    emojiPickerTabs: EmojiTab[];
    groupedEmojiList: Record<number, EmojiWithImages[]>;
    searchValue: string;
    onSelect: (emoji: EmojiWithImages) => void;
};

const EmojiGroupList = ({
    tabIndex,
    emojiPickerTabs,
    groupedEmojiList,
    searchValue,
    onSelect,
}: EmojiGroupListProps) => {
    const group = emojiPickerTabs.find((tab) => tab.groupKey === tabIndex);
    if (!group) {
        return null;
    }
    const filteredEmojiList = groupedEmojiList[group.groupKey]?.filter(
        (emoji) =>
            emoji.label.toLowerCase().includes(searchValue.toLowerCase()) ||
            emoji.tags?.some((tag) => tag.includes(searchValue.toLowerCase())),
    );

    if (!filteredEmojiList?.length) {
        return null;
    }

    return (
        <Box key={group.groupName}>
            <Box sx={sectionTitle}>{group.groupName}</Box>
            <Suspense fallback={<div />}>
                <EmojiList
                    title={group.groupName}
                    emojiList={filteredEmojiList}
                    onSelect={onSelect}
                />
            </Suspense>
        </Box>
    );
};

export default EmojiGroupList;
